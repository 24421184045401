body {
  font-family: sans-serif;
  background-color: #f7f5f4;
  overflow-y: scroll;
}

* {
  box-sizing: border-box;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

button {
  border: none;
  background: inherit;
  padding: 0;
}

button:hover {
  cursor: pointer;
}

button:focus {
  outline: 0;
}

a {
  color: inherit;
  text-decoration: none;
}
